import React from 'react';
import Footer from '../components/Footer';
import PrivacyPolicyInfo from '../components/PrivacyPolicyInfo';

const PrivacyPolicy = () => {
    return (
        <>
            <PrivacyPolicyInfo />
            <Footer/>
        </>

    )
}

export default PrivacyPolicy;


///<Clients />


