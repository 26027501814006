import React, { useState, useEffect } from 'react';    

import { useTranslation } from 'react-i18next';
import NavBar from './Navbar/NavBar';
import './CSHealthIntro.css'

import phpLogo from '../images/casestudy/phpLogo.svg';
import nodejsLogo from '../images/casestudy/nodejsLogo.svg';
import awsLogo from '../images/casestudy/awsLogo.svg';
import kotlinLogo from '../images/casestudy/kotlinLogo.svg';

import wpsLogo from '../images/casestudy/wpsLogo.svg';
import wooLogo from '../images/casestudy/wooLogo.svg';
import joomLogo from '../images/casestudy/joomLogo.svg';
import reactLogo from '../images/casestudy/reactLogo.svg';

import petsImg1 from '../images/casestudy/petsImg1.jpg';
import petsImg2 from '../images/casestudy/petsImg2.jpg';
import petsImg3 from '../images/casestudy/petsImg3.jpg';



const CSPetsIntro = () => {


    const { t } = useTranslation()

    const { pets_title, pets_subtitle1, pets_subtitle2 } = t("portfolio_all.pages.pets");

    const {
        pets_description,
        pets_client_title,
        pets_product_title,
        pets_work_title,
        pets_client_description,
        pets_product_description,
        pets_work_description } = t("portfolio_all.pages.pets");

    const { pets_profile,
        pets_profile_country_title,
        pets_profile_country_value,
        pets_profile_industry_titile,
        pets_profile_industry_value,
        pets_profile_team_titile,
        pets_profile_team_value } = t("portfolio_all.pages.pets");

    const { pets_goals,
        pets_goal1,
        pets_goal2,
        pets_goal3,
        pets_goal4,
    } = t("portfolio_all.pages.pets");

    const { pets_challanges,
        pets_challange_title1,
        pets_challange_1,
        pets_challange_title2,
        pets_challange_2,
        pets_challange_title3,
        pets_challange_3 } = t("portfolio_all.pages.pets");

    const { pets_solution_title,
        pets_solution_description1,
        pets_solution_description2,
        pets_solution_description3,
    } = t("portfolio_all.pages.pets");

    const { pets_techstack,
        pets_techstack1,
        pets_techstack2,
        pets_techstack3,
        pets_techstack4, } = t("portfolio_all.pages.pets");

    const { pets_results_title,
        pets_result_title1,
        pets_result_description1,
        pets_result_title2,
        pets_result_description2,
        pets_result_title3,
        pets_result_description3, } = t("portfolio_all.pages.pets");


        useEffect(() => {

            window.scrollTo(0, 0);
        }, []);

    return (

        <>
            <div className="squid" id='squid'>
                <div>
                    <NavBar />
                </div>

                {/* ---  INTRO  ---*/}


                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>




                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                {pets_title}</h1>
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_subtitle1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{pets_subtitle2}</p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={petsImg1} />
                        </div>

                    </div>
                </div>

                {/* ---  DESCRIPTION  ---*/}
       

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_description}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_client_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{pets_client_description}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_product_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{pets_product_description}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_work_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{pets_work_description}</p>
                            </div>
                        </div>

                    </div>


                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                            {pets_profile}</h1>
                        <p className='my-3 text-xl text-gray-600 font-regular'><b>{pets_profile_country_title}: </b> {pets_profile_country_value}</p> <p className='my-3 text-xl text-gray-600 font-regular'><b>{pets_profile_industry_titile}: </b> {pets_profile_industry_value}</p> <p className='my-3 text-xl text-gray-600 font-font-regular'><b>{pets_profile_team_titile}: </b> {pets_profile_team_value}</p>

                    </div>




                </div>


                {/* ---  GOALS  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">


                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={petsImg2} />
                        </div>



                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_goals}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{pets_goal1}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{pets_goal2}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{pets_goal3}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{pets_goal4}</li>
                            </div>
                        </div>


                    </div>
                </div>


                {/* ---  CHALLANGES  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_challanges}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_challange_title1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{pets_challange_1}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_challange_title2}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{pets_challange_2}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_challange_title3}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{pets_challange_3}</p>
                            </div>
                        </div>

                    </div>

                </div>


                {/* ---  SOLUTION  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">



                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_solution_title}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>


                            <div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{ pets_solution_description1}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{pets_solution_description2}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{pets_solution_description3}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={petsImg3} />
                        </div>

                    </div>


                    {/* ---  Tech Stack --- */}



                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                            {pets_techstack}</h1>
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="flex-col my-6 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                                <img alt="card img" className="rounded-t float-right" src={wpsLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {pets_techstack1}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={reactLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {pets_techstack2}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={nodejsLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {pets_techstack3}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={wooLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {pets_techstack4}</h3>
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>


                {/* ---  RESULT  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_results_title}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_result_title1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{pets_result_description1}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_result_title2}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{pets_result_description2}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {pets_result_title3}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{pets_result_description3}</p>
                            </div>
                        </div>

                    </div>

                </div>


            </div >
        </>
    )

}

export default CSPetsIntro;




// teleporting  https://play.teleporthq.io/projects/portfolio-page-bmoc3s/editor/9fa467fc-86c5-4001-96ba-81fb58f06cb4

