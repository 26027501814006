import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';                     /* Translations - RTL handling */
import { HashLink } from "react-router-hash-link";
//import heroVideo from '../videos/hero-video.mp4';
import heroVideo from '../videos/hero-video-background.mp4';
import './HeroVideo.css'


const HeroVideo = () => {

    const [isOpen, setOpen] = useState(false)

    //https://youtu.be/-79ahr9CrY0

    const { t } = useTranslation()

    useEffect(() => {

        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
    }, [i18n, i18n.language]);

    return (
        <>

            <div className="HeroVideo" id='herovideo'>
                <div className="video-box z-1">
                    <figure className="video-image">

                        <video className="video-hero" autoPlay={true} loop={true} controls={false} playsInline muted >
                            <source src={heroVideo} type="video/mp4" />
                        </video>

                        <div className="flex align-bottom ">
                            <HashLink smooth to="/#hero" className="lightbox-image overlay-box-hero"><span className="flaticon-down-icon" ><i className="ripple"></i></span> <i className="icon fas fa-play p-60" aria-hidden="true"></i> </HashLink>
                        </div>
                    </figure>
                </div>
            </div>
        </>
    )
}

export default HeroVideo;

