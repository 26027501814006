import React from 'react';
import Footer from '../components/Footer';
import CSDocsIntro from '../components/CSDocsIntro';
import Contact from './Contact';

const CaseStudyDocs = () => {
    return (
        <>
            <CSDocsIntro />
            <Contact />
        </>

    )
}

export default CaseStudyDocs;


///<Clients />


