import React, { useState, useEffect, useRef } from 'react';
import { render } from 'react-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';                     /* Translations - RTL handling */
import NavBar from '../components/Navbar/NavBar';
import Contact from '../pages/Contact';
import { Link } from 'react-router-dom'
import squidImg2 from '../images/casestudy/squidImg2.jpg';
import icoWeb from '../images/casestudy/ico_web.png';
import icoSaaS from '../images/casestudy/icoSaaS.png';
import icoMobile from '../images/casestudy/icoMobile.png';
import "react-image-lightbox/style.css";

//import '../fonts.css'



const Websites = [
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/foodily.jpg?alt=media&token=f95dc68c-18aa-424c-bdb9-5e08c2940508",
        url: 'https://www.foodily.me',
        font: 'font-serif'
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/pure.jpg?alt=media&token=9e883173-e4c3-49ca-9162-726d7ac14a67",
        url: 'https://purerelax.site',
        width: 320,
        height: 190,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/rovero.jpg?alt=media&token=4318969e-698a-448c-957a-c38920767ffa",
        url: 'https://hotel-rovero.site',
        width: 320,
        height: 148,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/adel.jpg?alt=media&token=dd6f4d86-1b1f-43ab-b717-4d8c2b34ed13",
        url: 'https://adelbeauty.site/',
        width: 320,
        height: 213,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/king.jpg?alt=media&token=6cc2d6dd-e1c9-4f5d-9403-8f0558af9208",
        url: 'https://king-solomon-hotel.site',
        width: 248,
        height: 320,
    },

    // 2nd row
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/park.jpg?alt=media&token=e06ac586-62ed-4eed-819a-47c62cbda641",
        url: 'https://www.park-hotel.site',
        width: 320,
        height: 113,
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/lena-1024x360.jpg?alt=media&token=74e2877a-fc46-49aa-8dd6-3b0e606f1f0e',
        url: 'https://lanas-nails.me',
        width: 313,
        height: 320,
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/tracht-1024x683.jpg?alt=media&token=d7382328-c0bd-42ed-9c35-dffcad0f5dce',
        url: '/',
        width: 320,
        height: 213,
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/just-1024x678.jpg?alt=media&token=6023ea27-ae3e-4cc8-9f81-b00291f0e621',
        url: 'https://just-print.me/',
        width: 640,
        height: 500,
    },
    // 3rd row
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/bari.jpg?alt=media&token=759e48aa-6677-4554-87d8-17227ae783ee',
        url: 'https://bari-food.me/',
        width: 240,
        height: 320,
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/wewear-1024x608.jpg?alt=media&token=ef7cdcbc-3f36-47b0-864f-364c155cd488',
        url: '/',
        width: 320,
        height: 190,
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/pets.jpg?alt=media&token=057afad2-7b4c-46b8-92e9-33f46abbdcb4',
        url: '/',
        width: 320,
        height: 148,
    },
    {
        src: "https://c6.staticflickr.com/9/8342/28897193381_800db6419e_b.jpg",
        url: '/',
        width: 320,
        height: 213,
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/carrent.jpg?alt=media&token=8e7c6655-4a94-4e3b-9006-854208b598fa',
        url: '/',
        width: 248,
        height: 320,
    },
];


const SaasApps = [
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/squid-1024x360.jpg?alt=media&token=24531164-d5aa-4742-a04e-7606acdbf135",
        url: '/casestudy-squid',
        title: 'Squid',
        description: 'CRM platform',
        width: 240,
        height: 320,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/quick-1024x360.jpg?alt=media&token=9bd2430f-f437-4d7f-9e48-717f2ec26c19",
        url: '/casestudy-quickspace',
        title: 'Quickspace',
        description: 'Real estate system',
        width: 320,
        height: 190,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/saasly-1024x683.jpg?alt=media&token=e403bfbc-ad1b-493d-8d95-9907c37811e3",
        url: '/casestudy-saasly',
        title: 'Saasly',
        description: 'Advanced analytics platform',
        width: 320,
        height: 148,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/logo-1024x678.jpg?alt=media&token=a52887ed-be2d-4272-988a-56503a625fcd",
        url: '/casestudy-logo',
        title: 'Logoipsum',
        description: 'Business management platform',
        width: 320,
        height: 213,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/fudizz-768x1024.jpg?alt=media&token=37fbfc49-4fb1-4979-8273-00e014a5efaf",
        url: '/portfolio-all',
        title: 'Fudizz',
        description: 'Food platform',
        width: 248,
        height: 320,
    },

    // 2nd row
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/carshop-1024x608.jpg?alt=media&token=b389752c-d3fd-4587-8aa0-9bb42e096ca9",
        url: '/portfolio-all',
        title: 'Carshop',
        description: 'Car marketplace',
        width: 248,
        height: 320,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/fairytail-1024x475.jpg?alt=media&token=ba5f16ad-9600-4552-95cd-8accfe4f1ba3",
        url: '/portfolio-all',
        title: 'Fairytail',
        description: 'Kids stories generator',
        width: 248,
        height: 320,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/foodzol-1024x683.jpg?alt=media&token=83548c2f-744e-4b7e-9ac1-3ef1108cfde8",
        url: '/portfolio-all',
        title: 'FoodZol',
        description: 'Online grocery store',
        width: 320,
        height: 213,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/medical-793x1024.jpg?alt=media&token=85c18269-1e02-4a4d-b448-2a8a00af1fe7",
        url: '/portfolio-all',
        title: 'Medicall',
        description: 'Medical orders app',
        width: 640,
        height: 500,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/bishulit.jpg?alt=media&token=5020ef4e-a52e-485f-a026-68106be5b93c",
        url: '/portfolio-all',
        title: 'Bishulit',
        description: 'Cooking web app',
        width: 640,
        height: 500,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/nadlanist.jpg?alt=media&token=f7a253a2-64bd-4270-8e25-289f4cf34afa",
        url: '/portfolio-all',
        title: 'Nadlanist',
        description: 'Real estate app',
        width: 640,
        height: 500,
    },

];


const MobileApps = [
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/fastgrab-1024x360%20(1).jpg?alt=media&token=fa0abe47-038e-44ef-9108-871ad6c6f8fd",
        url: '/casestudy-grab',
        title: 'Fastgrab',
        description: 'Food delivery app',
        width: 320,
        height: 148,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/medimart-1024x475.jpg?alt=media&token=b662450e-e839-4cff-a292-3ba18924fcb2",
        url: '/portfolio-all',
        title: 'Medimart',
        description: 'Healthcare platform',
        width: 320,
        height: 213,
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/RIDES%20-1024x683.jpg?alt=media&token=28634433-0432-4b4b-bc4f-7a13c80d3d0e",
        url: '/casestudy-rides',
        title: 'Rides',
        description: 'Bike store application',
        width: 248,
        height: 320,
    },

    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/metro-bank-1024x678.jpg?alt=media&token=67f610a0-a069-44fe-b995-cdd51314d1e1",
        url: '/portfolio-all',
        title: 'MetroBank',
        description: 'Payment application',
        width: 248,
        height: 320,
    },

    {
        src: "https://firebasestorage.googleapis.com/v0/b/carmarketplace-adc4b.firebasestorage.app/o/propGenuis.jpg?alt=media&token=c0e9e830-cf19-4dac-a79e-f5df0051a773",
        url: '/portfolio-all',
        title: 'PropGenius',
        description: 'Property Manager',
        width: 248,
        height: 320,
    },
];





const PortfolioPage = () => {

    const [index, setIndex] = useState(-1);
    const { t } = useTranslation()
    const { title, subtitle } = t("portfolio_all");
    const { web_title } = t("portfolio_all.websites");

    const {
        adel_title,
        adel_subtitle,
        adel_description,
        adel_learn,

        rovero_title,
        rovero_subtitle,
        rovero_description,
        rovero_learn,

        relax_title,
        relax_subtitle,
        relax_description,
        relax_learn,

        foodily_title,
        foodily_subtitle,
        foodily_description,
        foodily_learn,

        solomon_title,
        solomon_subtitle,
        solomon_description,
        solomon_learn,

        park_title,
        park_subtitle,
        park_description,
        park_learn,

        lena_title,
        lena_subtitle,
        lena_description,
        lena_learn,

        tracht_title,
        tracht_subtitle,
        tracht_description,
        tracht_learn,

        // wewear_title,
        // wewear_subtitle,
        // wewear_description,
        // wewear_learn,


    } = t("portfolio_all.websites");

    const { print_title, print_subtitle, print_description, print_learn } = t("portfolio_all.websites");
    const { bfood_title, bfood_subtitle, bfood_description, bfood_learn } = t("portfolio_all.websites");
    const { pets_title, pets_subtitle, pets_description, pets_learn } = t("portfolio_all.websites");

    const { saas_title, } = t("portfolio_all.saas");
    const { squid_title, squid_subtitle, squid_description, squid_learn } = t("portfolio_all.saas");
    const { qspace_title, qspace_subtitle, qspace_description, qspace_learn } = t("portfolio_all.saas");
    const { saasly_title, saasly_subtitle, saasly_description, saasly_learn } = t("portfolio_all.saas");
    const { ipsum_title, ipsum_subtitle, ipsum_description, ipsum_learn } = t("portfolio_all.saas");

    const { mobile_title, } = t("portfolio_all.mobile");

    const { grab_title, grab_subtitle, grab_description, grab_learn } = t("portfolio_all.mobile");
    const { health_title, health_subtitle, health_description, health_learn } = t("portfolio_all.mobile");
    const { rides_title, rides_subtitle, rides_description, rides_learn } = t("portfolio_all.mobile");
    const { netpay_title, netpay_subtitle, netpay_description, netpay_learn } = t("portfolio_all.mobile");
    const { goal_title, goal1, goal2, goal3, goal4 } = t("portfolio_all.pages.goals");

    const { design_title, } = t("portfolio_all.design");
    const { ux_title, ux_description, ux_learn } = t("portfolio_all.design");
    const { ui_title, ui_description, ui_learn } = t("portfolio_all.design");
    const { media_title, media_description, media_learn } = t("portfolio_all.design");
    const { docs_title, docs_description, docs_learn } = t("portfolio_all.design");
    const { wewear_title, wewear_subtitle, wewear_description, wewear_learn } = t("portfolio.wewear");

    useEffect(() => {

        window.scrollTo(0, 0);
        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
    }, [i18n, i18n.language]);

    /*
    const handleClick = (index) => {
        window.open('https://codesuits.com/casestudy-squid', "_self");
    };*/

    return (

        <>
            <div className="PortfolioPage" id='portfolio-all'>
                <div>
                    <NavBar />
                </div>

                {/* ---  INTRO  ---*/}


                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='portfolio' >

                    {/* lg:text-left */}

                    <div className="flex flex-col-reverse lg:flex-row pt-12 pb-28 justify-between" data-aos="fade-up">

                        {/* text-center lg:text-left */}

                        <div className="flex-col my-4  lg:my-0 lg:justify-end w-full  px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                {title}</h1>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                            </h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{subtitle}</p>
                            </div>
                        </div>

                        {/* <div className="lg:w-1/2 flex flex-col lg:mx-18 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={portfolioIntro} />
                        </div> */}

                    </div>
                </div>



                {/* YZ test */}




                {/* ---  Websites  ---*/}


                <div className="w-full m-auto  p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>


                    {/* ---  web title   ---*/}

                    <div class="py-4 px-2 mx-auto max-w-screen-xl sm:py-4 lg:px-6" data-aos="zoom-in" data-aos-delay="500">
                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">

                            <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-[F3F4F5] h-auto md:h-full flex flex-col">
                                <ul className="flex justify-left mb-4 md:mb-0">
                                    <li>
                                        <div className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                            <img alt="card img" className=" rounded-t float-right duration-1000 w-full" style={{ height: 38, width: 38 }} src={icoWeb} />
                                        </div>
                                    </li>
                                    <li className="ml-4">
                                        <h1 className="mb-5 md:text-3xl text-3xl font-bold text-[#3980C6] pr-2">
                                            {web_title}</h1>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* <section class="bg-white"></section>                     */}
                    <section class="bg-[F3F4F5]">

                        <div class="py-4 px-2 mx-auto max-w-screen-xl sm:py-4 lg:px-6">
                            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">


                                <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-gray-50 h-auto md:h-full flex flex-col">
                                    <a href={Websites[0].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow">
                                        {/* <img src="https://images.unsplash.com/photo-1510812431401-41d2bd2722f3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" /> */}
                                        <img src={Websites[0].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                        <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                        <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl }" >Foodily</h3>
                                        <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Coffee bar site</div>
                                    </a>
                                </div>

                                <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">

                                    <a href={Websites[1].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4">
                                        {/* <img src="https://images.unsplash.com/photo-1504675099198-7023dd85f5a3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" /> */}
                                        <img src={Websites[1].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                        <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                        <h3 class="z-10 text-2xl font-serif font-bold  text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">Purerelax</h3>
                                        <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Beauty & Wellness</div>
                                    </a>

                                    <div class="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">

                                        <a href={Websites[2].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40">
                                            <img src={Websites[2].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">Rovero</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Hotel website</div>
                                        </a>

                                        <a href={Websites[3].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40">
                                            {/* <img src="https://images.unsplash.com/photo-1626897505254-e0f811aa9bf7?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                             */}
                                            <img src={Websites[3].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">Adel Beauty</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Beauty & Wellness</div>
                                        </a>
                                    </div>
                                </div>

                                <div class="col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">

                                    <a href={Websites[4].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow">
                                        <img src={Websites[4].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                        <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                        <div><h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-2xl">King Solomon</h3></div>
                                        <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Hotel website</div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/*  2nd block */}

                        <div class="py-4 px-2 mx-auto max-w-screen-xl sm:py-4 lg:px-6">
                            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">


                                <div class="col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">

                                    <a href={Websites[5].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow">
                                        <img src={Websites[5].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                        <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                        <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">Park Hotel</h3>
                                        <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Hotel website</div>
                                    </a>
                                </div>

                                <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">

                                    <a href={Websites[6].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4">
                                        {/* <img src="https://images.unsplash.com/photo-1504675099198-7023dd85f5a3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                         */}
                                        <img src={Websites[6].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />

                                        <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                        <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">Lena's Nails</h3>
                                        <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Beauty & Wellness</div>
                                    </a>

                                    <div class="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">

                                        <a href={Websites[7].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40">
                                            {/* <img src="https://images.unsplash.com/photo-1571104508999-893933ded431?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                             */}
                                            <img src={Websites[7].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />

                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">Trachtenberg</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Distillery</div>
                                        </a>

                                        <a href={Websites[8].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40">
                                            {/* <img src="https://images.unsplash.com/photo-1626897505254-e0f811aa9bf7?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                             */}
                                            <img src={Websites[8].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />

                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">Justprint</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Printing website</div>
                                        </a>
                                    </div>
                                </div>

                                <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-gray-50 h-auto md:h-full flex flex-col">
                                    <a href={Websites[9].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow">
                                        {/* <img src="https://images.unsplash.com/photo-1510812431401-41d2bd2722f3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                        */}

                                        <img src={Websites[9].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />

                                        <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                        <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">Barifood</h3>
                                        <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Restaurant site</div>
                                    </a>
                                </div>

                            </div>
                        </div>

                        {/*  3rd block */}

                        <div class="py-4 px-2 mx-auto max-w-screen-xl sm:py-4 lg:px-6">
                            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">

                                <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-gray-50 h-auto md:h-full flex flex-col">
                                    <a href={Websites[10].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow">
                                        {/* <img src="https://images.unsplash.com/photo-1510812431401-41d2bd2722f3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                        */}
                                        <img src={Websites[10].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />

                                        <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                        <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">WeWear</h3>
                                        <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Fashion online site</div>
                                    </a>
                                </div>

                                <div class="col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">

                                    <a href={Websites[11].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow">
                                        {/* <img src="https://images.unsplash.com/photo-1693680501357-a342180f1946?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                         */}
                                        <img src={Websites[11].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />

                                        <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                        <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">PetShopToys</h3>
                                        <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Ecommerce site</div>
                                    </a>

                                </div>

                                <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">

                                    <a href={Websites[12].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4">
                                        {/* <img src="https://images.unsplash.com/photo-1504675099198-7023dd85f5a3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                        */}

                                        <img src={Websites[12].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />

                                        <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                        <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">Traveholic</h3>
                                        <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Travel site</div>
                                    </a>

                                    <div class="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">

                                        <a href={Websites[13].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40">
                                            {/* <img src="https://images.unsplash.com/photo-1571104508999-893933ded431?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                            */}
                                            <img src={Websites[13].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />

                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">Car4You</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Car agency</div>
                                        </a>

                                        {/* <div className='class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40'> */}
                                        <a class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40">
                                            <img src="https://images.unsplash.com/photo-1626897505254-e0f811aa9bf7?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">Salut</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>Cocktails landing page</div>
                                        </a>
                                        {/* </div> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>


                {/* ---  Web and SaaS  ---*/}

                {/* <div className="w-full m-auto  p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#FFFFFF' }}></div> */}
                <div className="w-full m-auto  p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>


                    {/* ---  Saas title   ---*/}

                    <div class="py-4 px-2 mx-auto max-w-screen-xl sm:py-4 lg:px-6" data-aos="zoom-in" data-aos-delay="500">
                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">

                            <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-[F3F4F5] h-auto md:h-full flex flex-col">
                                <ul className="flex justify-left mb-4 md:mb-0">
                                    <li>
                                        <div className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                            <img alt="card img" className=" rounded-t float-right duration-1000 w-full" style={{ height: 38, width: 38 }} src={icoSaaS} />
                                        </div>
                                    </li>
                                    <li className="ml-4">
                                        <h1 className="mb-5 md:text-3xl text-3xl font-bold text-[#3980C6] pr-2">
                                            {saas_title}</h1>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div>

                        <section class="bg-[F3F4F5]">

                            <div class="py-4 px-2 mx-auto max-w-screen-xl sm:py-4 lg:px-6">
                                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">


                                    <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-gray-50 h-auto md:h-full flex flex-col">
                                        <Link to={SaasApps[0].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                            <img src={SaasApps[0].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{SaasApps[0].title}</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{SaasApps[0].description}</div>
                                        </Link>
                                    </div>

                                    <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">

                                        <Link to={SaasApps[1].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>                                            {/* <img src="https://images.unsplash.com/photo-1504675099198-7023dd85f5a3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" /> */}
                                            <img src={SaasApps[1].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{SaasApps[1].title}</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{SaasApps[1].description}</div>
                                        </Link>

                                        <div class="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 pt-4">

                                            <Link to={SaasApps[2].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>                                                {/* <img src="https://images.unsplash.com/photo-1571104508999-893933ded431?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" /> */}
                                                <img src={SaasApps[2].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                                <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                                <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{SaasApps[2].title}</h3>
                                                <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{SaasApps[2].description}</div>
                                            </Link>

                                            <Link to={SaasApps[3].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                                <img src={SaasApps[3].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                                <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                                <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{SaasApps[3].title}</h3>
                                                <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{SaasApps[3].description}</div>
                                            </Link>
                                        </div>
                                    </div>

                                    <div class="col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">
                                        <Link to={SaasApps[4].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                            <img src={SaasApps[4].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{SaasApps[4].title}</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{SaasApps[4].description}</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            {/*  2nd block */}

                            <div class="py-4 px-2 mx-auto max-w-screen-xl sm:py-4 lg:px-6">
                                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">


                                    <div class="col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">

                                        <Link to={SaasApps[5].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                            {/* <img src="https://images.unsplash.com/photo-1693680501357-a342180f1946?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                         */}
                                            <img src={SaasApps[5].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />

                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{SaasApps[5].title}</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{SaasApps[5].description}</div>
                                        </Link>

                                    </div>

                                    <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">

                                        <Link to={SaasApps[6].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                            {/* <img src="https://images.unsplash.com/photo-1504675099198-7023dd85f5a3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                         */}
                                            <img src={SaasApps[6].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />

                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{SaasApps[6].title}</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{SaasApps[6].description}</div>
                                        </Link>

                                        <div class="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 pt-4">

                                            <Link to={SaasApps[7].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                                <img src={SaasApps[7].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />

                                                <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                                <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{SaasApps[7].title}</h3>
                                                <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{SaasApps[7].description}</div>
                                            </Link>

                                            <Link to={SaasApps[8].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                                <img src={SaasApps[8].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                                <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                                <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{SaasApps[8].title}</h3>
                                                <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{SaasApps[8].description}</div>
                                            </Link>
                                        </div>
                                    </div>


                                    <div class="col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">
                                        <Link to={SaasApps[9].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                            <img src={SaasApps[9].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{SaasApps[9].title}</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{SaasApps[9].description}</div>
                                        </Link>
                                    </div>

                                    <div class="col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">
                                        <Link to={SaasApps[10].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                            <img src={SaasApps[10].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{SaasApps[10].title}</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{SaasApps[10].description}</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            {/*  3rd block */}


                        </section>


                    </div>
                </div>





                {/* ---  Mobile  apps  ---*/}



                <div className="w-full m-auto  p-2 md:p-12 h-5/6 mb-16" id='about' style={{ backgroundColor: '#F3F4F5' }}>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>



                    {/* ---  Saas title   ---*/}

                    <div class="py-4 px-2 mx-auto max-w-screen-xl sm:py-4 lg:px-6" data-aos="zoom-in" data-aos-delay="500">
                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">

                            <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-[F3F4F5] h-auto md:h-full flex flex-col">
                                <ul className="flex justify-left mb-4 md:mb-0">
                                    <li>
                                        <div className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                            <img alt="card img" className=" rounded-t float-right duration-1000 w-full" style={{ height: 38, width: 38 }} src={icoMobile} />
                                        </div>
                                    </li>
                                    <li className="ml-4">
                                        <h1 className="mb-5 md:text-3xl text-3xl font-bold text-[#3980C6] pr-2">
                                            {mobile_title}</h1>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div className='pb-16'>
                        <section class="bg-[F3F4F5]">
                            <div class="py-4 px-2 mx-auto max-w-screen-xl sm:py-4 lg:px-6">
                                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">

                                    <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-gray-50 h-auto md:h-full flex flex-col">
                                        <Link to={MobileApps[0].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                            {/* <a href={MobileApps[0].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow"> */}
                                            {/* <img src="https://images.unsplash.com/photo-1510812431401-41d2bd2722f3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" /> */}
                                            <img src={MobileApps[0].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{MobileApps[0].title}</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{MobileApps[0].description}</div>
                                        </Link>
                                    </div>

                                    <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">
                                        <Link to={MobileApps[1].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                            {/* <a href={MobileApps[1].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4"> */}
                                            {/* <img src="https://images.unsplash.com/photo-1504675099198-7023dd85f5a3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" /> */}
                                            <img src={MobileApps[1].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{MobileApps[1].title}</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{MobileApps[1].description}</div>
                                        </Link>

                                        <div class="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 pt-4">

                                            <Link to={MobileApps[2].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                                {/* <a href={MobileApps[2].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40"> */}
                                                {/* <img src="https://images.unsplash.com/photo-1571104508999-893933ded431?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" /> */}
                                                <img src={MobileApps[2].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                                <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                                <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{MobileApps[2].title}</h3>
                                                <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{MobileApps[2].description}</div>
                                            </Link>

                                            <Link to={MobileApps[3].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                                {/* <a href={MobileApps[3].url} target='_blank' class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40"> */}
                                                <img src={MobileApps[3].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                                <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                                <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{MobileApps[3].title}</h3>
                                                <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{MobileApps[3].description}</div>
                                            </Link>
                                        </div>
                                    </div>


                                    <div class="col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">
                                        <Link to={MobileApps[4].url} class='group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow'>
                                            {/* <a href={MobileApps[4].url} class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow"> */}
                                            <img src={MobileApps[4].src} class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                                            <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                                            <h3 class="z-10 text-2xl font-serif font-bold text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">{MobileApps[4].title}</h3>
                                            <div class=" text-xl font-regular text-white absolute top-2 left-0 p-4  xs:text-xl md:text-xl"><br></br>{MobileApps[4].description}</div>
                                        </Link>
                                    </div>


                                </div>
                            </div>

                            {/*  2nd block */}



                            {/*  3rd block */}


                        </section>

                    </div>

                </div>





                {/* ---  GOALS  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between" data-aos="fade-up">


                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={squidImg2} />
                        </div>


                        {/* text-center lg:text-left */}

                        <div className="flex-col my-4  lg:my-0 lg:justify-end w-full lg:w-1/2 px-8 mb-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {goal_title}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{goal1}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{goal2}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{goal3}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{goal4}</li>
                            </div>
                        </div>
                    </div>
                </div>


                {/* ---  Contatct & Footer  ---*/}

                <div>
                    <Contact></Contact>
                </div>


            </div >
        </>
    )

}

export default PortfolioPage;




// teleporting  https://play.teleporthq.io/projects/portfolio-page-bmoc3s/editor/9fa467fc-86c5-4001-96ba-81fb58f06cb4

