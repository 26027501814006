import React from 'react';
import Footer from '../components/Footer';
import CSKingIntro from '../components/CSKingIntro';
import Contact from './Contact';

const CaseStudyKing = () => {
    return (
        <>
            <CSKingIntro />
            <Contact />
        </>

    )
}

export default CaseStudyKing;


///<Clients />


