import React from 'react';
import Footer from '../components/Footer';
import CSLanasIntro from '../components/CSLanasIntro';
import Contact from './Contact';

const CaseStudyLanas = () => {
    return (
        <>
            <CSLanasIntro />
            <Contact />
        </>

    )
}

export default CaseStudyLanas;


///<Clients />


