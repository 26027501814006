import React from 'react';
import Footer from '../components/Footer';
import CaseStudyMarketingIntro from '../components/CaseStudyMarketingIntro';
import Contact from './Contact';

const CaseStudyMarketing = () => {
    return (
        <>
            <CaseStudyMarketingIntro />
            <Contact />
        </>

    )
}

export default CaseStudyMarketing;


///<Clients />


