import React from 'react';
import Footer from '../components/Footer';
import CSAdelIntro from '../components/CSAdelIntro';
import Contact from './Contact';

const CaseStudyAdel = () => {
    return (
        <>
            <CSAdelIntro />
            <Contact />
        </>

    )
}

export default CaseStudyAdel;


///<Clients />


