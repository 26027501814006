import React, { useState, useEffect } from 'react';
import NavBar from './Navbar/NavBar';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


import './CSHealthIntro.css'

import figmaLogo from '../images/casestudy/figmaLogo.jpg';
import photoshopLogo from '../images/casestudy/photoshopLogo.svg';
import illustLogo from '../images/casestudy/illustLogo.svg';
import justLogo from '../images/casestudy/justLogo.svg';
import zeppelinLogo from '../images/casestudy/zeppelinLogo.svg';


import uiImg1 from '../images/casestudy/uiImg1.jpg';
import uiImg2 from '../images/casestudy/uiImg2.jpg';
import uiImg3 from '../images/casestudy/uiImg3.jpg';

import uiImg4 from '../images/casestudy/uiImg4.jpg';
import uiImg5 from '../images/casestudy/uiImg5.jpg';
import uiImg6 from '../images/casestudy/uiImg6.jpg';
import uiImg7 from '../images/casestudy/uiImg7.jpg';

const CSUIIntro = () => {

    const { t } = useTranslation()
    const { ui_title, ui_subtitle1 } = t("portfolio_all.pages.ui");

    const { ui_title2,
        ui_bullet1,
        ui_bullet2,
        ui_bullet3,
    } = t("portfolio_all.pages.ui");

    const { ui_title3,
        ui_bullet4,
        ui_bullet5,
        ui_bullet6,

    } = t("portfolio_all.pages.ui");

    const { ui_title4,
        ui_bullet7,
        ui_bullet8,
        ui_bullet9, } = t("portfolio_all.pages.ui");

    const { ui_title5,
        ui_bullet10,
        ui_bullet11,
    } = t("portfolio_all.pages.ui");

    const {
        ui_title6,
        ui_bullet12,
        ui_bullet13,
    } = t("portfolio_all.pages.ui");

    const { ui_title7,
        ui_bullet14, } = t("portfolio_all.pages.ui");

    const { ui_techstack,
        ui_techstack1,
        ui_techstack2,
        ui_techstack3,
        ui_techstack4,
        ui_techstack5, } = t("portfolio_all.pages.ui");


        useEffect(() => {

            window.scrollTo(0, 0);
        }, []);

    return (

        <>
            <div className="squid" id='squid'>
                <div>
                    <NavBar />
                </div>

                {/* ---  INTRO  ---*/}


                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                {ui_title}</h1>

                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ui_subtitle1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'></p>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uiImg1} />
                        </div>
                    </div>
                </div>


                {/* ---  Maintaining  ---*/}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uiImg2} />
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ui_title2}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet1}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet2}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet3}</li>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---  User-centricity  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ui_title3}</h1>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet4}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet5}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet6}</li>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uiImg3} />
                        </div>

                    </div>
                </div>


                {/* ---  Context  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >



                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uiImg4} />
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ui_title4}</h1>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet7}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet8}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet9}</li>
                            </div>
                        </div>


                    </div>
                </div>

                {/* ---  Hierarchy  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">


                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ui_title5}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet10}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet11}</li>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uiImg5} />
                        </div>


                    </div>
                </div>

                {/* ---  Context  ---*/}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >



                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uiImg6} />
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ui_title6}</h1>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet12}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet13} </li>
                            </div>
                        </div>


                    </div>
                </div>

                {/* ---  Minimalism  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">


                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ui_title7}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet14}</li>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uiImg7} />
                        </div>


                    </div>
                </div>


                {/* ---  Tech Stack --- */}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    {/* ---  Tech Stack --- */}

                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                            {ui_techstack}</h1>
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="flex-col my-6 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                                <img alt="card img" className="rounded-t float-right" src={figmaLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {ui_techstack1}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={photoshopLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {ui_techstack2}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={illustLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {ui_techstack3}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={justLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {ui_techstack4}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={zeppelinLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {ui_techstack5}</h3>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                {/* ---  RESULT  ---*/}



                <div className="m-auto max-w-6xl p-2 md:p-4 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex my-18 text-center lg:text-left lg:my-0 lg:justify-start w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <Link to="/service-design" className="bg-transparent border border-blue-900 hover:bg-blue-900 hover:border-blue-800 hover:text-white  text-blue-900 justify-center text-center rounded-lg px-10 py-3 flex items-center group">LEARN MORE ABOUT OUR UI EXPERTISE
                                <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div>
                    </div>



                </div>


            </div >
        </>
    )

}

export default CSUIIntro;




// teleporting  https://play.teleporthq.io/projects/portfolio-page-bmoc3s/editor/9fa467fc-86c5-4001-96ba-81fb58f06cb4

