import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import figmaLogo from '../images/casestudy/figmaLogo.jpg';
import balsamiqLogo from '../images/casestudy/balsamiqLogo.svg';
import axureLogo from '../images/casestudy/axureLogo.svg';
import miroLogo from '../images/casestudy/miroLogo.svg';
import sketchLogo from '../images/casestudy/sketchLogo.svg';

import photoshopLogo from '../images/casestudy/photoshopLogo.svg';
import illustLogo from '../images/casestudy/illustLogo.svg';
import justLogo from '../images/casestudy/justLogo.svg';
import zeppelinLogo from '../images/casestudy/zeppelinLogo.svg';

import uxImg1 from '../images/casestudy/uxImg1.jpg';
import uxImg2 from '../images/casestudy/uxImg2.jpg';
import uxImg3 from '../images/casestudy/uxImg3.jpg';
import uxImg4 from '../images/casestudy/uxImg4.jpg';
import uxImg5 from '../images/casestudy/uxImg5.jpg';
import uxImg6 from '../images/casestudy/uxImg6.jpg';
import uxImg7 from '../images/casestudy/uxImg7.jpg';

import uiImg1 from '../images/casestudy/uiImg1.jpg';
import uiImg2 from '../images/casestudy/uiImg2.jpg';
import uiImg3 from '../images/casestudy/uiImg3.jpg';

import uiImg4 from '../images/casestudy/uiImg4.jpg';
import uiImg5 from '../images/casestudy/uiImg5.jpg';
import uiImg6 from '../images/casestudy/uiImg6.jpg';
import uiImg7 from '../images/casestudy/uiImg7.jpg';

import marketingImg1 from '../images/casestudy/marketingImg1.jpg';
import marketingImg2 from '../images/casestudy/marketingImg2.jpg';
import marketingImg3 from '../images/casestudy/marketingImg3.jpg';

import uxIcon from '../images/uxIcon.svg';
import uiIcon from '../images/uiIcon.svg';
import marketIcon from '../images/marketIcon.svg';
import docIcon from '../images/docIcon.svg';

const DesignInfo = () => {

    //    useDocTitle('CodeSuits | UX/UI Design')


    const { t } = useTranslation()
    const { ux_title, ux_subtitle1 } = t("portfolio_all.pages.ux");

    const { ux_category_title1,
        ux_category_description1, } = t("portfolio_all.pages.ux");

    const { ux_category_title2,
        ux_category_description2,
    } = t("portfolio_all.pages.ux");

    const { ux_category_title3,
        ux_category_description3,
    } = t("portfolio_all.pages.ux");

    const { ux_category_title4,
        ux_category_description4,
    } = t("portfolio_all.pages.ux");


    const { ux_title2,
        ux_bullet1,
        ux_bullet2,
        ux_bullet3, } = t("portfolio_all.pages.ux");

    const { ux_title3,
        ux_bullet4,
        ux_bullet5,
        ux_bullet6, } = t("portfolio_all.pages.ux");

    const { ux_title4,
        ux_bullet7,
        ux_bullet8,
        ux_bullet9,
        ux_bullet10, } = t("portfolio_all.pages.ux");

    const { ux_title5,
        ux_bullet11,
        ux_bullet12,
        ux_bullet13,
        ux_bullet14, } = t("portfolio_all.pages.ux");

    const { ux_title6,
        ux_bullet15,
        ux_bullet16,
        ux_bullet17, } = t("portfolio_all.pages.ux");

    const { ux_title7,
        ux_bullet18,
        ux_bullet19,
        ux_bullet20,
        ux_bullet21,
        ux_bullet22,
        ux_bullet23, } = t("portfolio_all.pages.ux");

    const { ux_techstack,
        uxui_stack,
        ux_techstack1,
        ux_techstack2,
        ux_techstack3,
        ux_techstack4,
        ux_techstack5, } = t("portfolio_all.pages.ux");

    const { ui_techstack,
        ui_techstack1,
        ui_techstack2,
        ui_techstack3,
        ui_techstack4,
        ui_techstack5, } = t("portfolio_all.pages.ui");




    const { ui_title2,
        ui_bullet1,
        ui_bullet2,
        ui_bullet3,
    } = t("portfolio_all.pages.ui");

    const { ui_title3,
        ui_bullet4,
        ui_bullet5,
        ui_bullet6,

    } = t("portfolio_all.pages.ui");

    const { ui_title4,
        ui_bullet7,
        ui_bullet8,
        ui_bullet9, } = t("portfolio_all.pages.ui");

    const { ui_title5,
        ui_bullet10,
        ui_bullet11,
    } = t("portfolio_all.pages.ui");

    const {
        ui_title6,
        ui_bullet12,
        ui_bullet13,
    } = t("portfolio_all.pages.ui");

    const { ui_title7,
        ui_bullet14, } = t("portfolio_all.pages.ui");


    const { market_title2,
        market_bullet1,
        market_bullet2,
        market_bullet3,
        market_bullet4,
    } = t("portfolio_all.pages.marketing");


    const { web_case_study } = t("services_pages.webdev");



    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);





    return (
        <>

            <Helmet>
                <title>CodeSuits | UX/UI Design</title>
                <meta
                    name='description'
                    content='Our UX/UI designers create responsive UI for mobile and web applications with focus on a simple and effecient user experience'
                />
                <meta
                    name='keywords'
                    content="design, user experience, ux, user interface, ui, web design, mobile design, responsive ui, android ux/ui, android ux, android ui, android user experience, android user interface, ios ux/ui,  ios ux, ios user experience, ios user interface, web ux/ui, web ux, web user experience, web user interface, ממשק משתמש, חווית משתמש, עיצוב , פיגמה, מעצב לאפליקציה"
                />
            </Helmet>



            <div>
                <NavBar />
            </div>
            <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">



                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                                <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                    UX/UI DESIGNS</h1>

                                <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                    {ux_subtitle1}</h3>
                                <div>
                                    <p className='my-3 text-xl text-gray-600 font-semibold'></p>
                                </div>
                            </div>

                            <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                                <img alt="card img" className="rounded-t float-right" src={uxImg1} />
                            </div>
                        </div>
                    </div>




                    <section class="py-10 bg-white sm:py-16 lg:py-24">
                        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                            <div class="grid grid-cols-1 text-center sm:grid-cols-2 gap-y-8 lg:grid-cols-4 sm:gap-12">


                                <div>
                                    <div class="flex items-center justify-center w-20 h-20 mx-auto bg-blue-100 rounded-full">



                                        <img alt="card img" className="rounded-t float-right" src={uxIcon} />

                                        {/*
                                        <svg class="text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1.5"

                                                d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                                            />
                                        </svg>
                                        */}


                                    </div>
                                    <h3 class="mt-8 text-lg font-semibold text-black">{ux_category_title1}</h3>
                                    <p class="mt-4 text-sm text-gray-600">{ux_category_description1}</p>
                                </div>


                                <div>
                                    <div class="flex items-center justify-center w-20 h-20 mx-auto bg-orange-100 rounded-full">


                                        <img alt="card img" className="rounded-t float-right" src={uiIcon} />

                                        {/*}
                                        <svg class="text-orange-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                        </svg>

                                             */}
                                    </div>
                                    <h3 class="mt-8 text-lg font-semibold text-black">{ux_category_title2}</h3>
                                    <p class="mt-4 text-sm text-gray-600">{ux_category_description2}</p>
                                </div>




                                <div>
                                    <div class="flex items-center justify-center w-20 h-20 mx-auto bg-green-100 rounded-full">
                                        {/*}
                                        <svg class="text-green-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                                        </svg> */}

                                        <img alt="card img" className="rounded-t float-right" src={marketIcon} />




                                    </div>
                                    <h3 class="mt-8 text-lg font-semibold text-black">{ux_category_title3}</h3>
                                    <p class="mt-4 text-sm text-gray-600">{ux_category_description3}</p>
                                </div>

                                <div>
                                    <div class="flex items-center justify-center w-20 h-20 mx-auto bg-red-100 rounded-full">


                                        <img alt="card img" className="rounded-t float-right" src={docIcon} />


                                        {/*
                                        <svg class="text-red-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                                        </svg>
                                    */}


                                    </div>
                                    <h3 class="mt-8 text-lg font-semibold text-black">{ux_category_title4}</h3>
                                    <p class="mt-4 text-sm text-gray-600">{ux_category_description4}</p>
                                </div>
                            </div>
                        </div>
                    </section>



                    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                    </div>
                    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                    </div>
                    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                    </div>




                    {/* ---  Maintaining  ---*/}

                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                                <img alt="card img" className="rounded-t float-right" src={uxImg2} />
                            </div>

                            <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                                <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                    {ux_title2}</h1>


                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet1}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet2}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet3}</li>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ---  User-centricity  ---*/}


                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                                <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                    {ux_title3}</h1>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet4}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet5}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet6}</li>
                                </div>
                            </div>

                            <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                                <img alt="card img" className="rounded-t float-right" src={uxImg3} />
                            </div>

                        </div>
                    </div>


                    {/* ---  Context  ---*/}

                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                                <img alt="card img" className="rounded-t float-right" src={uxImg4} />
                            </div>

                            <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                                <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                    {ux_title4}</h1>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet7}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet8}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet9}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet10}</li>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* ---  Hierarchy  ---*/}



                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">


                            <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                                <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                    {ux_title5}</h1>


                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet11}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet12}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet13}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet14}</li>
                                </div>
                            </div>

                            <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                                <img alt="card img" className="rounded-t float-right" src={uxImg5} />
                            </div>


                        </div>
                    </div>


                    {/* ---  Context  ---*/}

                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                                <img alt="card img" className="rounded-t float-right" src={uxImg6} />
                            </div>

                            <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                                <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                    {ux_title6}</h1>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet15}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet16}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet17}</li>
                                </div>
                            </div>


                        </div>
                    </div>

                    {/* ---  Usability  ---*/}


                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">


                            <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                                <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                    {ux_title7}</h1>


                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet18}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet19}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet20}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet21}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet22}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet23}</li>
                                </div>
                            </div>

                            <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                                <img alt="card img" className="rounded-t float-right" src={uxImg7} />
                            </div>


                        </div>
                    </div>




                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                                <img alt="card img" className="rounded-t float-right" src={uiImg2} />
                            </div>

                            <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                                <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                    {ui_title2}</h1>


                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet1}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet2}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet3}</li>
                                </div>
                            </div>
                        </div>
                    </div>




                    {/* ---  User-centricity  ---*/}


                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                                <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                    {ui_title3}</h1>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet4}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet5}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet6}</li>
                                </div>
                            </div>

                            <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                                <img alt="card img" className="rounded-t float-right" src={uiImg3} />
                            </div>

                        </div>
                    </div>


                    {/* ---  Context  ---*/}


                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >



                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                                <img alt="card img" className="rounded-t float-right" src={uiImg4} />
                            </div>

                            <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                                <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                    {ui_title4}</h1>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet7}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet8}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{ui_bullet9}</li>
                                </div>
                            </div>


                        </div>
                    </div>





                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                                <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                    {market_title2}</h1>


                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{market_bullet1}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{market_bullet2}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{market_bullet3}</li>
                                    <li className='my-3 text-xl text-gray-600 font-semibold'>{market_bullet4}</li>
                                </div>
                            </div>

                            <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                                <img alt="card img" className="rounded-t float-right" src={marketingImg2} />
                            </div>


                        </div>
                    </div>



                    {/* ---  UX Tech Stack --- */}

                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                                {uxui_stack} </h1>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>
                            <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">



                                <div className="flex-col my-6 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                                    <img alt="card img" className="rounded-t float-right" src={figmaLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                {ux_techstack1}</h3>
                                        </div>

                                    </div>
                                </div>


                                <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                    alignItems: "center"
                                }}>
                                    <img alt="card img" className="rounded-t float-right" src={balsamiqLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                {ux_techstack2}</h3>
                                        </div>

                                    </div>
                                </div>


                                <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                    alignItems: "center"
                                }}>
                                    <img alt="card img" className="rounded-t float-right" src={axureLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                {ux_techstack3}</h3>
                                        </div>

                                    </div>
                                </div>


                                <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                    alignItems: "center"
                                }}>
                                    <img alt="card img" className="rounded-t float-right" src={miroLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                {ux_techstack4}</h3>
                                        </div>

                                    </div>
                                </div>


                                <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                    alignItems: "center"
                                }}>
                                    <img alt="card img" className="rounded-t float-right" src={sketchLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                {ux_techstack5}</h3>
                                        </div>

                                    </div>
                                </div>






                                <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                    alignItems: "center"
                                }}>
                                    <img alt="card img" className="rounded-t float-right" src={photoshopLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                Phsp.</h3>
                                        </div>

                                    </div>
                                </div>


                                <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                    alignItems: "center"
                                }}>
                                    <img alt="card img" className="rounded-t float-right" src={illustLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                Ilstr.</h3>
                                        </div>

                                    </div>
                                </div>


                                <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                    alignItems: "center"
                                }}>
                                    <img alt="card img" className="rounded-t float-right" src={zeppelinLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                {ui_techstack5}</h3>
                                        </div>

                                    </div>
                                </div>


                                <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                    alignItems: "center"
                                }}>
                                    <img alt="card img" className="rounded-t float-right" src={justLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                {ui_techstack4}</h3>
                                        </div>

                                    </div>
                                </div>



                            </div>



                        </div>
                    </div>


                    <div className=" mx-20 my-8 space-x-0 md:space-x-2 md:mb-8">
                        <Link to="/portfolio-all" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                            {web_case_study}
                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>



                </div>
            </div>
            <Footer />
        </>


    )
}

export default DesignInfo;