import React from 'react';
import Footer from '../components/Footer';
import CSUIIntro from '../components/CSUIIntro';
import Contact from './Contact';

const CaseStudyUI = () => {
    return (
        <>
            <CSUIIntro />
            <Contact />
        </>

    )
}

export default CaseStudyUI;


///<Clients />


