import React, { useState, useEffect } from 'react';
import NavBar from './Navbar/NavBar';
import { Link } from 'react-router-dom'
import './CSHealthIntro.css'

import { useTranslation } from 'react-i18next';



import figmaLogo from '../images/casestudy/figmaLogo.jpg';
import balsamiqLogo from '../images/casestudy/balsamiqLogo.svg';
import axureLogo from '../images/casestudy/axureLogo.svg';
import miroLogo from '../images/casestudy/miroLogo.svg';
import sketchLogo from '../images/casestudy/sketchLogo.svg';


import uxImg1 from '../images/casestudy/uxImg1.jpg';
import uxImg2 from '../images/casestudy/uxImg2.jpg';
import uxImg3 from '../images/casestudy/uxImg3.jpg';
import uxImg4 from '../images/casestudy/uxImg4.jpg';
import uxImg5 from '../images/casestudy/uxImg5.jpg';
import uxImg6 from '../images/casestudy/uxImg6.jpg';
import uxImg7 from '../images/casestudy/uxImg7.jpg';

const CSUXIntro = () => {


    const { t } = useTranslation()
    const { ux_title, ux_subtitle1 } = t("portfolio_all.pages.ux");

    const { ux_title2,
        ux_bullet1,
        ux_bullet2,
        ux_bullet3, } = t("portfolio_all.pages.ux");

    const { ux_title3,
        ux_bullet4,
        ux_bullet5,
        ux_bullet6, } = t("portfolio_all.pages.ux");

    const { ux_title4,
        ux_bullet7,
        ux_bullet8,
        ux_bullet9,
        ux_bullet10, } = t("portfolio_all.pages.ux");

    const { ux_title5,
        ux_bullet11,
        ux_bullet12,
        ux_bullet13,
        ux_bullet14, } = t("portfolio_all.pages.ux");

    const { ux_title6,
        ux_bullet15,
        ux_bullet16,
        ux_bullet17, } = t("portfolio_all.pages.ux");

    const { ux_title7,
        ux_bullet18,
        ux_bullet19,
        ux_bullet20,
        ux_bullet21,
        ux_bullet22,
        ux_bullet23, } = t("portfolio_all.pages.ux");

    const { ux_techstack,
        ux_techstack1,
        ux_techstack2,
        ux_techstack3,
        ux_techstack4,
        ux_techstack5, } = t("portfolio_all.pages.ux");

        useEffect(() => {

            window.scrollTo(0, 0);
        }, []);

    return (

        <>
            <div className="squid" id='squid'>
                <div>
                    <NavBar />
                </div>

                {/* ---  INTRO  ---*/}


                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>




                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                {ux_title}</h1>

                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ux_subtitle1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'></p>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uxImg1} />
                        </div>
                    </div>
                </div>


                {/* ---  Maintaining  ---*/}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uxImg2} />
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ux_title2}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet1}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet2}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet3}</li>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---  User-centricity  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ux_title3}</h1>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet4}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet5}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet6}</li>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uxImg3} />
                        </div>

                    </div>
                </div>


                {/* ---  Context  ---*/}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uxImg4} />
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ux_title4}</h1>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet7}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet8}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet9}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet10}</li>
                            </div>
                        </div>

                    </div>
                </div>

                {/* ---  Hierarchy  ---*/}



                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">


                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ux_title5}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet11}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet12}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet13}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet14}</li>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uxImg5} />
                        </div>


                    </div>
                </div>


                {/* ---  Context  ---*/}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uxImg6} />
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ux_title6}</h1>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet15}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet16}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet17}</li>
                            </div>
                        </div>


                    </div>
                </div>

                {/* ---  Usability  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">


                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {ux_title7}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet18}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet19}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet20}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet21}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet22}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{ux_bullet23}</li>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={uxImg7} />
                        </div>


                    </div>
                </div>


                {/* ---  Tech Stack --- */}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                            {ux_techstack}</h1>
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="flex-col my-6 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                                <img alt="card img" className="rounded-t float-right" src={figmaLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {ux_techstack1}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={balsamiqLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {ux_techstack2}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={axureLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {ux_techstack3}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={miroLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {ux_techstack4}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={sketchLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {ux_techstack5}</h3>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                {/* ---  RESULT  ---*/}



                <div className="m-auto max-w-6xl p-2 md:p-4 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex my-18 text-center lg:text-left lg:my-0 lg:justify-start w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <Link to="/service-design" className="bg-transparent border border-blue-900 hover:bg-blue-900 hover:border-blue-800 hover:text-white  text-blue-900 justify-center text-center rounded-lg px-10 py-3 flex items-center group">LEARN MORE ABOUT OUR UX EXPERTISE
                                <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div>
                    </div>



                </div>


            </div >
        </>
    )

}

export default CSUXIntro;




// teleporting  https://play.teleporthq.io/projects/portfolio-page-bmoc3s/editor/9fa467fc-86c5-4001-96ba-81fb58f06cb4

