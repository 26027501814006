import React from 'react';
import Footer from '../components/Footer';
import CSRoveroIntro from '../components/CSRoveroIntro';
import Contact from './Contact';

const CaseStudyRovero = () => {
    return (
        <>
            <CSRoveroIntro />
            <Contact />
        </>

    )
}

export default CaseStudyRovero;


///<Clients />


