import React, { useState, useEffect } from 'react';
import waImg from '../../icons/whatsapp.png';


const ContactSideBar = () => {


    const [bottom, setBottom] = useState(!window.scrollY);
    const [isOpen, setisOpen] = React.useState(false);

    function handleClick() {
        setisOpen(!isOpen);
    }


    useEffect(() => {
        const scrollHandler = () => {
            if (window.pageYOffset > 10) {
                setBottom(false)
            }
            else {
                setBottom(true)
            }
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [bottom]);

    return (


        <nav id='navbar' className={` fixed right-0 mr-2 -bottom-6 h-44 w-20 z-30 transition duration-300 ease-in-out   bg-transparent rounded-ss-xl rounded-s-xl `}>
            <div className='flex '>

                <div className="flex flex-row justify-between items-center py-8" >
                    <div className="flex flex-row justify-center md:px-4 items-center text-center font-semibold">
                        <ul className="flex justify-center mb-4 md:mb-0">
                            <li className="ml-6 lg:ml-4">

                                <div className="flex flex-col justify-center items-center text-center font-semibold ">

                                    <a aria-label="Chat on WhatsApp" href="https://wa.me/972546604257" target='_blank'> <img alt="Chat on WhatsApp"
                                        src={waImg}  className='w-8 h-8 sm:w-8 sm:h-8 md:w-10 md:h-8 lg:w-8 lg:h-8 xl:w-8 xl:h-8   border-2 border-white rounded-full' />
                                    </a>

                                    {/* <a aria-label="Chat on WhatsApp" href="https://www.linkedin.com" target='_blank'> <img alt="LinkedIn"
                                        src={lnkdImg} width='48' height='48' className='border-2 border-white rounded-full' />
                                    </a>

                                    <a aria-label="Chat on WhatsApp" href="https://www.facebook.com" target='_blank'> <img alt="Facebook"
                                        src={fbImg} width='48' height='48' className='border-2 border-white rounded-full' />
                                    </a> */}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>


        </nav>
    )

}


export default ContactSideBar;