import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import axios from 'axios';
import Notiflix from 'notiflix';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Contact from '../pages/Contact';

import 'swiper/css';
import './About.css';

import i18n from 'i18next';


// images
import parkLogo from '../images/casestudy/parkLogo.jpg';
import lanasLogo from '../images/casestudy/lanasLogo.jpg';
import trachtLogo from '../images/casestudy/trachtLogo.jpg';
import shukLogo from '../images/casestudy/shukLogo.png';
import petsLogo from '../images/casestudy/petsLogo.png';
import healthLogo from '../images/casestudy/health-logo.jpg';
import netpayLogo from '../images/casestudy/netpayLogo.jpg';
import mapImg from '../images/casestudy/mapImg.jpg'
import aboutCEO from '../images/casestudy/aboutCEO.jpg'
import aboutCTO from '../images/casestudy/aboutCTO.jpg'
import aboutCOO from '../images/casestudy/aboutCOO.jpg'
import aboutCFO from '../images/casestudy/aboutCFO.jpg'
import aboutCRO from '../images/casestudy/aboutCRO.jpg'
import aboutVPD from '../images/casestudy/aboutVPDesign.jpg'
import processImg from '../images/processImg.jpg'

import nativeIcon from '../images/mobileNativeIcon.svg';
import crossIcon from '../images/mobileCross.svg';
import sdkIcon from '../images/mobileSdk.svg';

import hollisticsIcon from '../images/mobileHolistic.svg';
import icon_req from '../images/icon_req.png';
import icon_offer from '../images/icon_offer.png';
import icon_test from '../images/icon_test.png';
import icon_work from '../images/icon_work.png';
import deployIcon from '../images/icon_deploy.png';
//import icon_work from '../images/icon_work.png';

const swiperOptions = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 6,
    spaceBetween: 30,
    navigation: false,
    autoplay: {
        delay: 500,
        disableOnInteraction: true,
        reverseDirection: false,
    },
    loop: true,

    breakpoints: {
        320: {
            slidesPerView: 1,
            // spaceBetween: 30, 225
        },
        575: {
            slidesPerView: 1,
            // spaceBetween: 30, 162
        },
        767: {
            slidesPerView: 2,
            // spaceBetween: 30, 
        },
        991: {
            slidesPerView: 3,
            // spaceBetween: 30,
        },
        1199: {
            slidesPerView: 4,
            // spaceBetween: 30,
        },
        1350: {
            slidesPerView: 6,
            // spaceBetween: 30,
        },
    }


};

const swiperOptions2 = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 6,
    spaceBetween: 30,
    navigation: false,
    autoplay: {
        delay: 500,
        disableOnInteraction: true,
        reverseDirection: true,
    },
    loop: true,

    breakpoints: {
        320: {
            slidesPerView: 1,
            // spaceBetween: 30, 225
        },
        575: {
            slidesPerView: 1,
            // spaceBetween: 30, 162
        },
        767: {
            slidesPerView: 2,
            // spaceBetween: 30, 
        },
        991: {
            slidesPerView: 3,
            // spaceBetween: 30,
        },
        1199: {
            slidesPerView: 4,
            // spaceBetween: 30,
        },
        1350: {
            slidesPerView: 6,
            // spaceBetween: 30,
        },
    }


};

const About = () => {
    //    useDocTitle('CodeSuits | Data Solutions')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const { t } = useTranslation()

    const { mobile_title,
        mobile_subtitle1,
        mobile_subtitle2,
        mobile_subtitle3,
        contact
    } = t("services_pages.mobile");

    const { native_title,
        native_description,
    } = t("services_pages.mobile.native");

    const { cross_title,
        cross_description1,
        cross_description2,
    } = t("services_pages.mobile.cross");

    const { sdk_title,
        sdk_description,
    } = t("services_pages.mobile.sdk");

    const { hollistic_title,
        hollistic_description,
    } = t("services_pages.mobile.hollistic");

    const { web_case_study } = t("services_pages.webdev");


    const {

        process_title,
        process_subtitle,

        process_phase1_title,
        process_phase1_description1,
        process_phase1_description2,

        process_phase2_title,
        process_phase2_description1,
        process_phase2_description2,
        process_phase2_schedule_title,
        process_phase2_schedule_description,

        process_phase3_title,
        process_phase3_description1,
        process_phase3_description2,
        process_phase3_schedule_title,
        process_phase3_schedule_description,


        process_phase4_title,
        process_phase4_description1,
        process_phase4_description2,
        process_phase4_schedule_title,
        process_phase4_schedule_description,

        process_phase5_title,
        process_phase5_description1,
        process_phase5_description2,

    } = t("company_pages.process");





    const {

        tech_data_letstalk,

    } = t("tech_pages.tech_data");




    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
    }, [i18n, i18n.language]);


    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)

        axios({
            method: "post",
            url: process.env.REACT_APP_CONTACT_API,
            data: fData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                clearInput()
                //handle success
                Notiflix.Report.success(
                    'Success',
                    response.data.message,
                    'Okay',
                );
            })
            .catch(function (error) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                //handle error
                const { response } = error;
                if (response.status === 500) {
                    Notiflix.Report.failure(
                        'An error occurred',
                        response.data.message,
                        'Okay',
                    );
                }
                if (response.data.errors !== null) {
                    setErrors(response.data.errors)
                }

            });





    }




    return (
        <>

            <Helmet>
                <title>CodeSuits | Process</title>
                <meta
                    name='description'
                    content='Our Data experts will help plan and impleent for your business data-based and ml/ai solutions'
                />
                <meta
                    name='keywords'
                    content="data, database, artificial intelligence, ai, machine learning, ml, server, aws, gcp, azure, cloud, cloud-based solutions, cloud solutions, מידע, בינה מלאכותית, ענןֿ, דאטה, אחסון בענן"
                />
            </Helmet>



            <div>
                <NavBar />
            </div>

            <div id='process' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">


                    <section class="py-10 bg-gray-50 sm:py-16 lg:py-24">
                        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                            <div class="text-center">
                                <div class="max-w-2xl mx-auto text-center">
                                    <h2 className="font-bold leading-tight text-center lg:text-center text-blue-900  text-4xl">{process_title}</h2>
                                    <p class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">{process_subtitle}
                                    </p>
                                </div>
                            </div>


                            <div className="flex lg:justify-end w-full mt-16" data-aos="fade-up" data-aos-delay="700">
                                <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={processImg} />
                            </div>


                            <div class="px-5 py-8 mt-12 bg-white lg:mt-20 lg:p-16">
                                <div class="grid grid-cols-1 gap-12 lg:gap-16 sm:grid-cols-2">
                                    
                                    <div class="flex items-start">

                                        <img alt="card img" className="rounded-t w-16 h-16 float-right ml-8" src={icon_req} />
                                        <div class="ml-5">
                                            <h3 class="text-lg font-semibold text-black">{process_phase1_title}</h3>
                                            <p class="mt-4 text-base text-gray-600">{process_phase1_description1}</p>
                                                <p class="mt-4 text-base text-gray-600">{process_phase1_description2}</p>
                                                
                                        </div>
                                    </div>

                                    <div class="flex items-start">
                                        <img alt="card img" className="rounded-t w-16 h-16 float-right ml-8" src={icon_offer} />
                                        <div class="ml-5">
                                            <h3 class="text-lg font-semibold text-black">{process_phase2_title}</h3>
                                            <p class="mt-4 text-base text-gray-600">{process_phase2_description1}</p>
                                            <p class="mt-4 text-base text-gray-600">{process_phase2_description2}</p>

                                            <p class="mt-4 text-base text-gray-600">{process_phase2_schedule_title}</p>
                                            {/* <p class="mt-4 text-base text-gray-600">{process_phase2_schedule_description}</p> */}

                                        </div>
                                    </div>


                                    <div class="flex items-start">

                                        <img alt="card img" className="rounded-t w-16 h-16 float-right ml-8" src={icon_work} />

                                        <div class="ml-5">
                                            <h3 class="text-lg font-semibold text-black">{process_phase3_title}</h3>
                                            <p class="mt-4 text-base text-gray-600">{process_phase3_description1}</p>
                                            <div class="mt-4 text-base text-black">{process_phase3_description2}</div>

                                            <p class="mt-4 text-base text-gray-600">{process_phase3_schedule_title}</p>
                                            <div class="mt-4 text-base text-black">{process_phase3_schedule_description}</div>
                                        </div>
                                    </div>


                                    <div class="flex items-start">
                                        <img alt="card img" className="rounded-t w-16 h-16 float-right ml-8" src={icon_test} />

                                        <div class="ml-5">
                                            <h3 class="text-lg font-semibold text-black">{process_phase4_title}</h3>
                                            <p class="mt-4 text-base text-gray-600">{process_phase4_description1}</p>
                                            <p class="mt-4 text-base text-gray-600">{process_phase4_description2}</p>
                                            <p class="mt-4 text-base text-gray-600">{process_phase4_schedule_title}</p>
                                            <p class="mt-4 text-base text-gray-600">{process_phase4_schedule_description}</p>

                                        </div>
                                    </div>



                                    <div class="flex items-start">
                                        <img alt="card img" className="rounded-t w-16 h-16 float-right ml-8" src={deployIcon} />

                                        <div class="ml-5">
                                            <h3 class="text-lg font-semibold text-black">{process_phase5_title}</h3>
                                            <p class="mt-4 text-base text-gray-600">{process_phase5_description1}</p>
                                            <p class="mt-4 text-base text-gray-600">{process_phase5_description2}</p>

                                        </div>
                                    </div>





                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>


            <Contact />

            {/* 
            <section class="py-10 bg-gray-100 sm:py-16 lg:py-24  ">

                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 my-24"  >
                </div>

                <div className="container mx-auto  px-4 lg:px-20" data-aos="zoom-in">
                    <div className="w-full bg-white p-8  md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">

                        <section class="pt-10 pb-8 overflow-hidden sm:pt-16 lg:pt-24">


                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center my-12">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        {process_phase1_title}</h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600"><b> {process_phase1_description1} </b></p>
                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600"><b> {process_phase1_description2} </b></p>
                                </div>


                            </div>



                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center my-12">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        {process_phase2_title}</h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600"><b> {process_phase2_description1} </b></p>
                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600"><b> {process_phase2_description2} </b></p>

                                </div>

                                <div className="flex lg:justify-end w-full mt-16" data-aos="fade-up" data-aos-delay="700">
                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={mapImg} />
                                </div>

                            </div>


                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center my-12">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        {process_phase3_title}</h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600"><b> {process_phase3_description1} </b></p>
                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600"><b> {process_phase3_description2} </b></p>

                                </div>


                                <div class="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full  sm:mt-12 lg:mt-20 lg:grid-cols-3  w-full gap-x-12 gap-y-6">


                                    <div class="overflow-hidden bg-white rounded-2xl shadow-2xl ">
                                        <div class="px-8 py-12">

                                            <img class="flex justify-center mx-auto" src={aboutCEO} alt="" />

                                            <p class="text-base font-semibold tex-tblack mt-9">Yuval Zohar </p>
                                            <p class="mt-1 text-base text-gray-600"> CEO & Co-Founder</p>
                                        </div>
                                    </div>


                                    <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                        <div class="px-8 py-12">

                                            <img class="flex justify-center mx-auto" src={aboutCTO} alt="" />

                                            <p class="text-base font-semibold tex-tblack mt-9">Evgeny Zapisotsky</p>
                                            <p class="mt-1 text-base text-gray-600">CTO & Co-Founder</p>
                                        </div>
                                    </div>

                                    <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                        <div class="px-8 py-12">

                                            <img class="flex justify-center mx-auto" src={aboutCOO} alt="" />

                                            <p class="text-base font-semibold tex-tblack mt-9">Eitan Lifshitz</p>
                                            <p class="mt-1 text-base text-gray-600">COO</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full  sm:mt-12 lg:mt-20 lg:grid-cols-3  w-full gap-x-12 gap-y-16">


                                    <div class="overflow-hidden bg-white rounded-2xl shadow-2xl ">
                                        <div class="px-8 py-12">

                                            <img class="flex justify-center mx-auto" src={aboutCFO} alt="" />

                                            <p class="text-base font-semibold tex-tblack mt-9">Danit Cohen</p>
                                            <p class="mt-1 text-base text-gray-600"> CFO</p>
                                        </div>
                                    </div>


                                    <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                        <div class="px-8 py-12">

                                            <img class="flex justify-center mx-auto" src={aboutCRO} alt="" />
                                            <p class="text-base font-semibold tex-tblack mt-9">Shavit Kochavi</p>
                                            <p class="mt-1 text-base text-gray-600">CRO</p>
                                        </div>
                                    </div>

                                    <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                        <div class="px-8 py-12">

                                            <img class="flex justify-center mx-auto" src={aboutVPD} alt="" />

                                            <p class="text-base font-semibold tex-tblack mt-9">Debora Levy</p>
                                            <p class="mt-1 text-base text-gray-600">VP UX/UI</p>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl my-14">
                                <div class="max-w-2xl mx-auto text-center my-12">
                                </div>
                            </div>

                            <div className="auto-container " >
                                <Swiper {...swiperOptions2} className="clients-carousel owl-carousel owl-theme ">

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={healthLogo} style={{ height: 76, width: 76 }} />
                                            </div>
                                        </div>
                                    </SwiperSlide>



                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={parkLogo} style={{ height: 76, width: 76 }} />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={lanasLogo} style={{ height: 76, width: 76 }} />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={trachtLogo} style={{ height: 76, width: 76 }} />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={shukLogo} style={{ height: 76, width: 76 }} />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={petsLogo} style={{ height: 76, width: 76 }} />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={netpayLogo} style={{ height: 76, width: 76 }} />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                </Swiper>
                            </div>

                            <div class="max-w-2xl mx-auto text-center lg:pt-24">

                                <Link to="/get-demo" class="inline-flex items-center justify-center px-8 py-4 text-base font-semibold text-blue-900 transition-all duration-200 border-2 border-blue-900 rounded-md mt-9 hover:bg-blue-900 hover:text-white hover:border-blue-900 focus:bg-gray-900 focus:text-white focus:border-gray-900">
                                    {tech_data_letstalk}
                                </Link>

                            </div>

                        </section>



                    </div>
                </div >



            </section> */}







            {/* <Footer /> */}
        </>


    )
}

export default About;