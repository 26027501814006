import React from 'react';
import Footer from '../components/Footer';
import CSPrintIntro from '../components/CSPrintIntro';
import Contact from './Contact';

const CaseStudyJustPrint = () => {
    return (
        <>
            <CSPrintIntro />
            <Contact />
        </>

    )
}

export default CaseStudyJustPrint;


///<Clients />


