import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/hero-img.png';

import { useTranslation } from 'react-i18next';


const languages = [
    { code: "en", lang: "English" },
    { code: "he", lang: "Hebrew" },
    { code: "ru", lang: "Russian" },
];



const LanguageSelector = () => {

    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {

        i18n.changeLanguage(lng);

    };

    useEffect(() => {
        document.body.dir = i18n.dir() || 'ltr'
    }, [i18n, i18n.language])


    return (

        <div className="btn-container">
            {
                languages.map((lng) => {
                    return (
                        <button className={lng.code === i18n.language ? "selected" : ""}
                            key={lng.code}
                            onClick={() => changeLanguage(lng.code)}
                        >
                            {lng.lang}
                        </button>
                    );
                })}</div>
    );
};



export default LanguageSelector;