import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NavBar from './Navbar/NavBar';
import { HashLink } from 'react-router-hash-link';

import phpLogo from '../images/casestudy/phpLogo.svg';
import nodejsLogo from '../images/casestudy/nodejsLogo.svg';
import wpsLogo from '../images/casestudy/wpsLogo.svg';
import wooLogo from '../images/casestudy/wooLogo.svg';
import joomLogo from '../images/casestudy/joomLogo.svg';


import roveroIntro from '../images/casestudy/roveroIntro.jpg';
import roveroImg2 from '../images/casestudy/roveroImg2.jpg';
import roveroImg3 from '../images/casestudy/roveroImg3.jpg';



const CSRoveroIntro = () => {


    const { t } = useTranslation()

    const { rovero_page_title } = t("portfolio_all.pages.rovero");
    const { rovero_subtitle1, rovero_subtitle2, rovero_description, rovero_client_title, rovero_product_title, rovero_work_title, rovero_client_description, rovero_product_description, rovero_work_description } = t("portfolio_all.pages.rovero");
    const { rovero_profile, rovero_profile_country_title, rovero_profile_country_value, rovero_profile_industry_titile, rovero_profile_industry_value, rovero_profile_team_titile, rovero_profile_team_value } = t("portfolio_all.pages.rovero");
    const { rovero_goals, rovero_goal1, rovero_goal2, rovero_goal3, rovero_goal4, rovero_goal5 } = t("portfolio_all.pages.rovero");

    const { rovero_challanges,
        rovero_challange_title1,
        rovero_challange_1,
        rovero_challange_title2,
        rovero_challange_2,
        rovero_challange_title3,
        rovero_challange_3 } = t("portfolio_all.pages.rovero");


    const { rovero_solution_title,
        rovero_solution_description1,
        rovero_solution_description2,
        rovero_solution_description3 } = t("portfolio_all.pages.rovero");


    const { rovero_techstack,
        rovero_techstack1,
        rovero_techstack2,
        rovero_techstack3,
        rovero_techstack4 } = t("portfolio_all.pages.rovero");


    const { rovero_results_title,
        rovero_result_title1,
        rovero_result_description1,
        rovero_result_title2,
        rovero_result_description2,
        rovero_result_title3,
        rovero_result_description3 } = t("portfolio_all.pages.rovero");


    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    return (

        <>
            <div className="squid" id='squid'>
                <div>
                    <NavBar />
                </div>

                {/* ---  INTRO  ---*/}


                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>




                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">



                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                            {rovero_page_title}</h1>


                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_subtitle1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{rovero_subtitle2}</p>
                            </div>

                            {/* <div>
                                <a className='my-3 text-xl text-gray-600 font-semibold' href='https://hotel-rovero.site/' target='_blank'><u>www.hotel-rovero.site</u> </a>
                            </div> */}

                            <HashLink className="text-white bg-blue-500 hover:bg-blue-700 inline-flex items-center justify-center w-auto px-6 py-3 my-4 shadow-xl rounded-xl" smooth target='_blank' to="https://hotel-rovero.site">
                                Review
                            </HashLink>

                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={roveroIntro} />
                        </div>

                    </div>
                </div>



                {/* ---  DESCRIPTION  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_description}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                               
                                 {rovero_client_title} 
                                </h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{rovero_client_description}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_product_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{rovero_product_description}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_work_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{rovero_work_description}</p>
                            </div>
                        </div>

                    </div>


                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                            {rovero_profile}</h1>
                        <p className='my-3 text-xl text-gray-600 font-regular'><b>{rovero_profile_country_title}: </b> {rovero_profile_country_value}</p> <p className='my-3 text-xl text-gray-600 font-regular'><b>{rovero_profile_industry_titile}: </b> {rovero_profile_industry_value}</p> <p className='my-3 text-xl text-gray-600 font-font-regular'><b>{rovero_profile_team_titile}: </b> {rovero_profile_team_value}</p>

                    </div>




                </div>



                {/* ---  GOALS  ---*/}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={roveroImg2} />
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_goals}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{rovero_goal1}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{rovero_goal2}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{rovero_goal3}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{rovero_goal4}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{rovero_goal5}</li>
                            </div>
                        </div>


                    </div>
                </div>


                {/* ---  CHALLANGES  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_challanges}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_challange_title1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{rovero_challange_1}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_challange_title2}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{rovero_challange_2}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_challange_title3}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{rovero_challange_3}</p>
                            </div>
                        </div>

                    </div>

                </div>


                {/* ---  SOLUTION  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">



                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_solution_title}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>


                            <div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{rovero_solution_description1}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{rovero_solution_description2}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{rovero_solution_description3}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={roveroImg3} />
                        </div>

                    </div>


                    {/* ---  Tech Stack --- */}


                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                            {rovero_techstack}</h1>
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="flex-col my-6 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                                <img alt="card img" className="rounded-t float-right" src={phpLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {rovero_techstack1}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={wooLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {rovero_techstack2}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={wpsLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {rovero_techstack3}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={joomLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {rovero_techstack4}</h3>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                {/* ---  RESULT  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_results_title}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_result_title1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{rovero_result_description1}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_result_title2}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{rovero_result_description2}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {rovero_result_title3}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{rovero_result_description3}</p>
                            </div>
                        </div>

                    </div>

                </div>


            </div >
        </>
    )

}

export default CSRoveroIntro;




// teleporting  https://play.teleporthq.io/projects/portfolio-page-bmoc3s/editor/9fa467fc-86c5-4001-96ba-81fb58f06cb4

