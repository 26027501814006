import React, { useState, useEffect, useRef } from 'react';
import NavLinks from '../Navbar/NavLinks';
import { HashLink } from 'react-router-hash-link';
import NavbarNew from "./NavbarNew";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Tooltip } from 'react-tooltip'

import appLogo from '../../images/logo192.png'; 
import FloatingIcon from '../FloatingIcon';
import whatsapp from '../../icons/whatsapp.png';
import accountImg from '../../images/icons/icon_account.png'

import "./NavLinks.css";

//import appLogo from '../../images/website_log.png';


const NavBar = () => {

    const navRef = useRef();

    const [top, setTop] = useState(!window.scrollY);
    const [isOpen, setisOpen] = React.useState(false);

    function handleClick() {
        setisOpen(!isOpen);
    }


    useEffect(() => {
        const scrollHandler = () => {
            if (window.pageYOffset > 10) {
                setTop(false)
            }
            else {
                setTop(true)
            }
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);


    const showNavbar = () => {
        navRef.current.classList.toggle('responsive_nav');
    }

    return (

        // <NavbarNew />
        // <Router>
        //     <NavbarNew />
        //     <Routes>
        //         <Route path="/" />
        //         <Route path="/products" />
        //         <Route path="/contact" />
        //         <Route path="/about" />
        //     </Routes>
        // </Router>

        <nav id='navbar' className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-16 ${!top && 'bg-white shadow-lg'}`}>
            <div className="flex flex-row justify-between items-center py-4" >
                <div className="flex flex-row justify-center md:px-2 items-center text-center font-semibold">
                    <ul className="flex justify-center mb-4 md:mb-0">
                        <li className="ml-4">

                            <HashLink smooth to="/#herovideo"><img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 36, width: 36 }} src={appLogo} />
                            </HashLink>

                            {/* <div className=" flex flex-row justify-center md:px-12 md:mx-12 items-center text-center font-semibold gap-4">
                                <HashLink smooth to="/#herovideo"><img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 46, width: 46 }} src={appLogo} />
                                </HashLink>
                            </div> */}
                        </li>

                        <li>
                            <HashLink className="tooltip inline-flex items-center justify-center w-auto lg:px-2 px-4" smooth target='_blank' to="https://app.codesuits.com">
                                <img src={accountImg} style={{ width: 32, height: 32 }} data-tooltip-target='tooltip-default'></img>
                            </HashLink>


                            <Tooltip anchorSelect=".tooltip" place="top">
                                Customers
                            </Tooltip>
                        </li>
                    </ul>
                </div>

                <div className="group flex flex-col items-center">
                    <button className="p-2 rounded-lg lg:hidden text-blue-900" onClick={handleClick}>
                        <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            {isOpen && (
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                            )}
                            {!isOpen && (
                                <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                            )}
                        </svg>
                    </button>

                    <div className='hidden space-x-6 lg:inline-block p-5'>
                        <NavLinks />
                    </div>

                    <div className={`fixed transition-transform duration-300 ease-in-out transit flex justify-center left-0 w-full h-auto rounded-md p-24 bg-white lg:hidden shadow-xl top-14 ${isOpen ? "block" : "hidden"} `}>
                        <div className='flex flex-col space-y-6'>
                            <NavLinks />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )

}


export default NavBar;
