import React from 'react';
import Footer from '../components/Footer';
import CSQuickSpaceIntro from '../components/CSQuickSpaceIntro';
import Contact from './Contact';

const CaseStudyQuickSpace = () => {
    return (
        <>
            <CSQuickSpaceIntro />
            <Contact />
        </>

    )
}

export default CaseStudyQuickSpace;


///<Clients />


