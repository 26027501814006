import React from 'react';
import Footer from '../components/Footer';
import CSLogoIntro from '../components/CSLogoIntro';
import Contact from './Contact';

const CaseStudyLogo = () => {
    return (
        <>
            <CSLogoIntro />
            <Contact />
        </>

    )
}

export default CaseStudyLogo;


///<Clients />


