import React from 'react';
import Footer from '../components/Footer';
import CSBariIntro from '../components/CSBariIntro';
import Contact from './Contact';

const CaseStudyBari = () => {
    return (
        <>
            <CSBariIntro />
            <Contact />
        </>

    )
}

export default CaseStudyBari;


///<Clients />


